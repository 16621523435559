.Logos {
	background: $light-gray;
	border-bottom: 3px solid $yellow;
	padding: 10px 0;

	.wrap {
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.Logos .Logo {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 1em;
	text-align: center;

	@media (min-width: 350px) {
		width: 50%;
	}

	img {
		height: 40px;
		max-height: 50px;
	}

	@include grid-media($tablet-portrait) {
		margin: 0 40px;
		padding: 0;
		text-align: auto;
		width: auto;

		img {
			height: auto;
			margin: auto;
			max-width: 150px;
		}
	}
}
.LogosLabel {
	color: $medium-gray;
	font-family: $heading-font-family;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	width: 100%;

	@include grid-media($tablet-portrait) {
		width: auto;
		text-align: right;
	}
}
