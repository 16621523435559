.PostList {
    margin: 0;
    padding: 0;
}

.PostList--reports {
    padding: 0 $small-spacing/2;
}

.PostListItem {
    @include list-reset;
    margin-bottom: $small-spacing;

    #{$text-list} {
        color: $dark-gray;
        font-family: $base-font-family;
    }
}

.PostListItem--first {
    .PostListItem-image {
        float: none;
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.PostListItem-image {
    float: left;
    margin-right: $small-spacing;
    margin-bottom: $small-spacing/2;

    img {
        border-radius: $base-border-radius;
        display: block;
    }
}

.PostListItem-title {
    font-family: $base-font-family;
    line-height: 30px;
    margin: 0;

    > a {
        color: $dark-gray;

        &:hover {
            color: $action-color;
        }
    }
}

.PostListItem-excerpt,
.PostListItem-meta {
    font-size: 13px;

    #{$text-list} {
        font-size: 13px;
    }
}

.PostListItem-excerpt > p:last-of-type {
    margin-bottom: 0;
}

.PostListItem-meta date,
h5.PostListItem-subtitle {
    color: $medium-gray;
    font-family: $heading-font-family;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 5px;
}

.PostListSummary {
    background: #eee;
    border: $base-border;
    border-radius: $base-border-radius;
    margin-bottom: $small-spacing;
    padding: 10px 20px;
}

.PostListSummary-title {
    color: $base-font-color;
    font-size: $base-font-size;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 10px;
}

.PostListSummary-link {
    font-family: $heading-font-family;
    font-size: 12px;
    font-weight: 700;
}

.PostList--reports .PostListItem-title {
    font-family: $heading-font-family;
    font-size: 20px;
}

.PostList--reports {
    .PostListItem-image {
        width: 50px;
        margin-bottom: 0;
    }
}
