/* Print Style Sheet */
@media print {
	@import "style";
	body {
		background: white;
		color: black;
		margin: 0;
	}

	.SingleProperty-nav,
	.Nav,
	.SiteHeader-right,
	.social-links,
	.Social-actions,
	.SingleProperty-mapLink,
	.SingleProperty-contact,
	.SiteFooter-social,
	.SiteFooter-logos,
	.ScrollTop,
	.ScrollBottom {
		display: none;
	}

	.SiteContainer {
		display: block;
		margin: 0 auto;
		max-width: 1200px;
	}

	.SingleProperty-detail {
		margin-bottom: 10px;
	}

	.SingleProperty-detailKey {
		font-weight: bold;
	}

	.SingleProperty-features-wrap h5,
	.SingleProperty-finance h5,
	.SingleProperty-location h5 {
		font-size: 20px;
	}
}
