.NewsletterSection {
    background: $blue;
    background: radial-gradient(circle, #4E6CBA 0%, #3A518D 100%);
    padding: $base-spacing 0;
}

.NewsletterSection-title,
.NewsletterSection-subtitle {
    color: #fff;
}

.NewsletterSection-title {
    font-weight: 400;
    margin-bottom: 10px;
}

.NewsletterSection-subtitle {
    font-family: $heading-font-family;
    font-weight: 700;
    margin: 0;
}

.NewsletterSection-form {
    margin-top: $small-spacing;

    .mc-field-group {
        @include margin-auto;
        align-items: center;
        display: flex;
        max-width: 640px;

        @media( max-width: 500px ) {
            flex-direction: column;
        }

        input[type="email"] {
            width: 100%;
            background: #fff;
            border-radius: $base-border-radius;
            margin-bottom: $small-spacing;

            &::placeholder {
                font-family: $base-font-family;
            }

            @media( min-width: 500px ) {
                margin-bottom: 0;
                margin-right: 1em;
            }

        }

        input[type="submit"] {
            width: 100%;
            background: #fff;
            color: $blue;
            height: 46px;
            padding: 12px 40px;

            &:hover {
                background: $light-gray;
            }

            @media( min-width: 500px ) {
                max-width: 160px;
            }

        }
    }
}
