.Tabs-nav {
	margin-bottom: $small-spacing;
}

.Tabs-link {
	border-bottom: 2px solid transparent;
	color: $medium-gray;
	display: block;
	font-family: $heading-font-family;
	font-size: 14px;
	margin-bottom: 5px;
	margin-right: $small-spacing/2;
	text-decoration: none;
	transition: all 0.3s ease-in-out;

	@include grid-media($tablet-portrait) {
		display: inline-block;
		margin-bottom: auto;
	}

	&:hover,
	&.is-active {
		color: $base-font-color;
		border-bottom: 2px solid $blue;
		text-decoration: none;
	}
}

.Tab {
	display: none;

	&.is-active {
		display: block;
	}

	#sr-map-search {
		height: 480px;
	}
}

.Search-properties {
	position: relative;
}

.loader {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
}
