.SiteFooter {
    background-color: $blue;
    background: radial-gradient(circle, #4e6cba 0%, #3a518d 100%);
    padding: $small-spacing 0;

    #{$text-list},
    a {
        color: #fff;
    }

    a {
        font-weight: bold;
    }

    @include grid-media($tablet-landscape) {
        padding: $base-spacing 0;
    }

    .wrap {
        align-items: center;
        flex-direction: column;
        display: flex;
    }
}

.SiteFooter-menu {
    width: 100%;

    @include grid-media($tablet-portrait) {
        width: auto;
    }
}

.SiteFooter-disclaimer {
    max-width: 1140px;
    text-align: justify;
}

.SiteFooter-logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $small-spacing;
    width: 100%;

    @include grid-media($tablet-portrait) {
        flex-direction: row;
        margin-bottom: 0;
    }
}

.SiteFooter-contact {
    justify-content: space-between;
    width: 100%;
}

.SiteFooter-social {
    margin: $small-spacing 0;

    &.u-alignMiddle {
        flex-direction: row;
    }

    a {
        margin-right: $small-spacing;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover svg {
            fill: darken($action-color, 10);
        }
    }

    svg {
        fill: #fff;
        height: 30px;
        width: 30px;
        transition: all $base-duration $base-timing;
    }
}

.SiteFooter-contactInfo p {
    margin: 0;
}
