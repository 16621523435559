.page .Content,
.single .Content,
.blog .Content,
.archive .Content,
.error404 .Content,
.date.post-type-archive-report .Content,
.search .Content {
	margin-bottom: $base-spacing;
	margin-top: $base-spacing;

	@include grid-media($tablet-landscape) {
		margin-top: 0;
		padding: 40px 30px;
	}
}

.post-type-archive-report .Content {
	padding-top: 0;
}

.home .Content {
	margin-bottom: 0;

	a:hover {
		text-decoration: none;
	}
	@include grid-media($tablet-portrait) {
		padding: 40px 0;
		padding-bottom: 0;
	}
}

.page-template-template-reports .Content {
	padding: 0;
}

.ContentWrap--fullwidth .Content {
	padding: 40px 0;
	padding-bottom: 0;
}

.Content a {
	transition: all $base-duration $base-timing;
}

.Content a:hover {
	text-decoration: underline;
}

.Content iframe {
	width: 100%;
	height: 300px;
}

.page-id-19211 .Content,
.LinkList {
	p {
		font-family: $heading-font-family;
	}

	p,
	.LinkListItem {
		padding: 5px;
	}

	p:nth-child(even),
	.LinkListItem:nth-child(even) {
		background: $light-gray;
	}
}

.page-id-18801 p {
	margin-bottom: 40px;
}