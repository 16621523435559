/**
 * Clean link
 *
 * Link without text decoration
 */
.u-linkClean,
.u-linkClean:hover,
.u-linkClean:focus,
.u-linkClean:active {
    text-decoration: none;
}

/**
 * Complex link
 *
 * Link to target a specific content with underline
 *
 * Example HTML:
 *
 * <a class="u-linkComplex" href="#">
 *     Link Complex
 *     <span class="u-linkComplexTarget">target</span>
 * </a>
 */
.u-linkComplex,
.u-linkComplex:hover,
.u-linkComplex:focus,
.u-linkComplex:active {
    text-decoration: none;

    .u-linkComplexTarget {
        text-decoration: underline !important;
    }
}

/**
 * Block link
 *
 * Display links in block
 */
.u-linkBlock,
.u-linkBlock:hover,
.u-linkBlock:focus,
.u-linkBlock:active {
    display: block;
    text-decoration: none;
}

/**
 * Arrow Link
 *
 * Adds an arrow after the link text
 */
.u-linkArrow {
    > a {
        color: $dark-gray;

        &:hover {
            color: $action-color;
        }
    }

    &:hover {
        &:after {
            margin-left: $small-spacing;
        }
    }

    &:after {
        background-image: url('assets/icons/svg-icons/link-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        display: inline-block;
        height: 0.75em;
        margin-left: $small-spacing - 5px;
        transition: all $base-duration $base-timing;
        width: 0.75em;
    }
}

.u-linkDate {
    position: relative;

    > a {
        display: block;
        width: 80%;
    }

    > span {
        position: absolute;
        right: 0;
        top: 0;
    }
}
