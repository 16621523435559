.FlipJobsSection {
	margin: 60px 0;
}

.FlipJobs {
	@include grid-collapse($mqs-desktop);
	@include grid-container;
}

.FlipJob {
	@include grid-column(12, $mqs-desktop);
	margin-bottom: $small-spacing;

	@include grid-media($tablet-portrait) {
		@include grid-column(4, $mqs-desktop);
		margin-bottom: 0;
	}
}

.FlipJob-image {
	a {
		display: block;
		position: relative;
	}

	img {
		border-radius: $base-border-radius;
		display: block;

		@include grid-media($tablet-landscape) {
			min-height: 275px;
		}
	}
}

.FlipJob-label {
	background: #fff;
	border-radius: $base-border-radius;
	color: $base-font-color;
	padding: 5px 15px;
	position: absolute;
	top: 20px;
	right: 30px;

	> span {
		align-items: center;
		display: flex;
		font-family: $heading-font-family;
		font-size: 12px;

		&:before {
			background-image: url("assets/icons/svg-icons/rotate-left.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			content: "";
			display: inline-block;
			height: 14px;
			margin-right: 5px;
			width: 14px;
		}
	}
}

.FlipJob-header {
	margin-top: 8px;
}

.FlipJob-title {
	font-size: 18px;
	margin-bottom: 5px;

	> a {
		color: $base-font-color;
		font-family: $base-font-family;
	}
}

.FlipJob-date {
	color: #868686;
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 5px;
}

.FlipJob-description {
	font-size: 13px;
}

.Post + .FlipJob-description {
	margin-bottom: $base-spacing;
}
