.Post {
	margin-bottom: 60px;
}

.post-type-archive-report .Post--excerpt,
.category .Post--excerpt,
.page-template-template-past-reports .Post--excerpt {
	align-items: flex-start;
	display: flex;
	flex-direction: column;

	@include grid-media($tablet-portrait) {
		flex-direction: row;
	}
}

.PostImage {
	margin-bottom: $small-spacing;
	margin-right: $small-spacing;

	&--is-full {
		margin-right: 0;
	}

	img {
		border-radius: $base-border-radius;
		display: block;
		margin: 0 auto;
		width: auto;

		.post-type-archive-report &,
		.category &,
		.page-template-template-past-reports .Post--excerpt {
			max-width: 200px;
			width: 100px;

			@include grid-media($tablet-landscape) {
				width: 200px;
			}
		}
	}
}

.PostTitle {
	font-family: $base-font-family;
	font-weight: 700;
	margin-bottom: 5px;

	> a {
		color: $base-font-color;

		&:hover {
			color: $blue;
			text-decoration: none;
		}
	}
}

.PostSubTitle {
	color: $medium-gray;
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 15px;
}

.PostMeta {
	color: $medium-gray;
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 15px;
}

.PostPromo {
	background: $action-color;
	border-radius: $base-border-radius;
	margin: $small-spacing 0;
	padding: $base-spacing;
	text-align: center;

	#{$text-list} {
		color: #fff;
	}

	a.button {
		background: #fff;
		color: $action-color;

		&:hover {
			background: darken($action-color, 10);
			color: #fff;
		}
	}
}

.PostBody {
	p:last-of-type {
		margin-bottom: 0;
	}

	a:hover {
		text-decoration: underline;
	}
}

.PageDescription {
	background: $light-gray;
	border-radius: $base-border-radius;
	margin-bottom: $small-spacing;
	padding: 2em 1em;

	p:last-child {
		margin-bottom: 0;
	}

	.wrap {
		width: 100%;
	}

	@include grid-media($tablet-landscape) {
		.wrap {
			padding: 0 1em;
		}
	}
}

a.read-more {
	appearance: none;
	background-color: $action-color;
	border: 0;
	border-radius: $base-border-radius;
	clear: both;
	color: #fff;
	cursor: pointer;
	display: block;
	font-family: $heading-font-family;
	font-size: $base-font-size;
	-webkit-font-smoothing: antialiased;
	font-weight: 600;
	line-height: 1;
	margin: 0 auto;
	margin-top: $small-spacing;
	padding: $small-spacing $small-spacing;
	text-align: center;
	text-decoration: none;
	transition: background-color $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	width: 166px;

	@media only screen and (min-width: 1024px) {
		padding: $small-spacing $base-spacing;
	}

	&:hover,
	&:focus {
		background-color: shade($action-color, 20%);
		color: #fff;
		text-decoration: none;
	}
}

.Post-divider {
	background: $action-color;
	height: 5px;
	margin: 60px 20%;
}

.PostInfo {
	overflow: auto;
}

.Post:last-of-type + .Post-divider {
	display: none;
}

.CategoryPostsList {
	ol {
		margin-left: 0;
	}

	ol > li {
		font-family: $heading-font-family;
		font-weight: bold;
	}
}

.category-aspen-real-estate-news-tim-estin-in-the-news .Post--excerpt {
	flex-direction: column;
}

.category-published-articles .PostImage {
	width: 100%;
}