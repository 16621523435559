.QuickLook {
    margin-bottom: $small-spacing;
    padding: 20px;
    @include grid-media($tablet-portrait) {
        margin-bottom: 0;
        padding: 60px;
    }
}

.QuickLook-images {
    align-items: center;
    display: flex;
}

.QuickLook-image {
    margin-right: 2%;

    &:last-of-type {
        margin-right: 0;
    }
}
