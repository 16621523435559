.ReportsSection {
    background: $light-gray;
    padding: 100px 0 60px;
}

.ReportsSection-header {
    align-items: flex-end;
    display: flex;
    margin-bottom: $base-spacing;
    position: relative;
}

.ReportsSection-info {
    max-width: 75%;
}

.ReportsSection-badge {
    margin-left: auto;
    right: 0;
    bottom: -30px;

    @include grid-media($tablet-landscape) {
        position: absolute;
    }

    img {
        display: block;
    }
}

.ReportsSection-subtitle {
    margin: 0;
}

.ReportsGrid {
    @include grid-collapse($mqs-desktop);
    @include grid-container;
}

.ReportsGridItem {
    @include grid-column(12, $mqs-desktop);
    border-radius: $base-border-radius;
    display: block;
    margin-bottom: $small-spacing;
    min-height: 286px;
    padding: 2em 15px;
    position: relative;
    text-align: center;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include grid-media($tablet-portrait) {
        @include grid-column(4, $mqs-desktop);
        padding: 2em 30px;
    }

    &:hover {
        h4 {
            color: $action-color;
        }
    }
}

.ReportsGridItem-content {
    #{$title-list} {
        margin: 0;
    }

    h4 {
        color: $base-font-color;
        font-family: $base-font-family;
        font-size: 24px;
        line-height: 32px;
        transition: color $base-duration $base-timing;
    }
}

.ReportsSection-footer {
    justify-content: center;
    padding-top: $small-spacing;
}
