fieldset {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
}

legend {
    font-weight: 600;
    margin-bottom: $small-spacing / 2;
    padding: 0;
}

label {
    display: block;
    font-weight: 600;
    margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
    display: block;
    font-family: $base-font-family;
    font-size: $base-font-size;
}

#{$all-text-inputs} {
    appearance: none;
    background-color: #fff;
    border: $base-border;
    border-color: #dddddd;
    border-radius: $base-border-radius;
    border-width: 2px;
    box-shadow: $form-box-shadow;
    box-sizing: border-box;
    margin-bottom: $small-spacing;
    padding: 0.75em 0.625em;
    transition: border-color $base-duration $base-timing;
    width: 100%;

    &:hover {
        border-color: shade($base-border-color, 20%);
    }

    &:focus {
        border-color: $action-color;
        box-shadow: $form-box-shadow-focus;
        outline: none;
    }

    &:disabled {
        background-color: shade($base-background-color, 5%);
        cursor: not-allowed;

        &:hover {
            border: $base-border;
        }
    }

    &::placeholder {
        color: tint($base-font-color, 40%);
        font-family: $heading-font-family;
    }
}

textarea {
    resize: vertical;
}

[type="checkbox"],
[type="radio"] {
    display: inline;
    margin-right: $small-spacing / 2;
}

[type="file"] {
    margin-bottom: $small-spacing;
    width: 100%;
}

label {
    font-family: $heading-font-family;
    font-size: 14px;
    margin-bottom: 8px;
}

select {
    margin-bottom: $small-spacing;
    width: 100%;
}

body .gform_wrapper .top_label div.ginput_container {
    margin-top: 0 !important;
}

.gform_wrapper label.gfield_label {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_first,
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_last,
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_middle {
    label {
        color: $medium-gray;
    }

    input {
        margin-bottom: 8px;
    }
}

.PropertyDetails .gform_wrapper h3.gform_title,
.PropertyDetails .gform_wrapper {
    margin-top: 0;
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    padding: 13px 8px !important;
}

.gform_wrapper.gf_browser_ie .gform_footer input.button {
    padding: $small-spacing $small-spacing !important;
}
