// Variables
$color-white: #fff;

$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-linkedin: #0077b5;
$color-pinterest: #c92228;

// Mixins
@mixin social_link( $service, $color ) {
    &[href*="#{$service}"] {
        background-color: $color;
        border-radius: 2px;
        color: $color-white;
        transition: background-color linear .2s;

        &:hover {
            background-color: darken($color, 15);
        }
    }
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.social-links {
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        display: inline-block;
        border-radius: $base-border-radius;
        margin-bottom: 10px;
        width: 49%;

        @include grid-media($tablet-portrait) {
            margin-bottom: 0;
            width: auto;
        }
    }

    &__link {
        display: block;
        font-family: $heading-font-family;
        font-size: 14px;
        padding: 6px 12px;
        text-decoration: none;

        // If you want to add an icon with CSS
        &:before {
            // content: "";
        }

        // Style hooks into each link
        @include social_link( facebook, $color-facebook );
        @include social_link( twitter, $color-twitter );
        @include social_link( linkedin, $color-linkedin );
        @include social_link( pinterest, $color-pinterest );
    }

    &__text {
        // Style hook into the text
        display: flex;
        align-items: center;

        svg {
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }
    }
} // .social-links

.Social-actions {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: none;

    @include grid-media($tablet-landscape) {
        display: flex;
    }

    li {
        border-radius: $base-border-radius;
        list-style-type: none;
        margin-left: 2%;
        &:hover {
            a {
                background-color: darken($light-gray, 10);
            }
        }

        a {
            background: $light-gray;
            color: $base-font-color;
            display: block;
            font-family: $heading-font-family;
            font-size: 14px;
            padding: 6px 12px;
            text-decoration: none;
            transition: all $base-duration $base-timing;

            span {
                align-items: center;
                display: flex;
            }

            svg {
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }
        }
    }
}
