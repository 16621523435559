.SummaryData {
    margin: 0;
    padding: 0;
}

.SummaryData-dataset {
    @include list-reset;

    header {
        flex-wrap: wrap;

        @media (min-width: 780px) {
            flex-direction: column;
        }

        @media (min-width: 1000px) {
            flex-direction: row;
        }
    }

    footer {
        .Dataset-values {
            display: flex;
            flex-wrap: wrap; 

            @media (min-width: 780px) {
                flex-direction: column;
            }

            @media (min-width: 1000px) {
                flex-direction: row;
            }
        }
    }
}

.Dataset-percentage,
.Dataset-label,
.Dataset-value {
    font-family: $heading-font-family;
}

.Dataset-percentage {
    font-size: 24px;
    font-weight: 700;
    margin-right: 5px;
}

.Dataset-label {
    color: $medium-gray;
    font-size: 14px;
}

.Dataset-value {
    font-size: 12px;
    margin-right: 5px;

    @media (min-width: 780px) {
        display: block;
    }

    @media (min-width: 1000px) {
        display: inline;
    }

    &:last-child {
        margin-right: 0;
    }
}

.Dataset-year {
    font-weight: 700;
}

.Dataset-percentage--isPositive {
    color: $green;
}

.Dataset-percentage--isNegative {
    color: red;
}
