.u-cf::before,
.u-cf::after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.u-cf::after {
    clear: both;
}

.u-floatLeft {
    float: left;
}

.u-floatRight {
    float: right;
}