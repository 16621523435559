.SiteHeader {
	color: $base-font-color;
	padding: 10px 0;
	position: relative;
	z-index: 500;

	@include grid-media($tablet-portrait) {
		padding: 5px 0;
	}

	.wrap {
		flex-direction: row;
		justify-content: space-between;
	}
}

.SiteName,
.SiteDescription {
	font-family: $base-font-family;
}

.SiteName {
	color: $base-font-color;
	font-size: 28px;
	letter-spacing: 1px;
	line-height: 40px;
	margin: 0;
}

.SiteCreds,
.SiteDescription {
	color: $medium-gray;
	font-weight: 400;
}

.SiteCreds {
	font-family: $heading-font-family;
	font-size: 16px;
}

.SiteDescription {
	font-size: 14px;
	margin: 0;
}

.SiteHeader-right {
	text-align: right;
	max-width: 220px;
}

figure.SothebyLogo {
	float: right;
	margin: 0;

	img {
		display: block;
		float: right;
	}

	&.desktop {
		display: none;
		@media (min-width: 1025px) {
			display: block;
		}
	}

	&.mobile {
		background-color: white;
		padding: $base-spacing;
		text-align: center;
		width: 100%;

		@include grid-media($tablet-portrait) {
			display: none;
		}
	}
}

.SiteHeader-phone {
	clear: both;
	padding-top: 10px;
	display: none;

	@media (min-width: 1025px) {
		display: block;
		font-size: 12px;
	}

	@media (min-width: 1205px) {
		font-size: $base-font-size;
	}

	svg {
		fill: $action-color;
		display: inline;
		height: 16px;
		width: 16px;
		position: relative;
		top: 2px;
	}
}
