body {
	left: 0;
	position: relative;
	transition: left 0.5s $base-timing;
	overflow-x: hidden;
	width: 100%;

	&.is-open {
		left: -260px;
		overflow: hidden;
		position: fixed;
		@media only screen and (min-width: 1025px) {
			left: 0;
			overflow-y: auto;
		}
	}
}

.wrap {
	margin: 0 auto;
	width: 90%;
	max-width: 1140px;
}

.ContentWrap--sidebar {
	@include grid-collapse($sidebar-right);
	@include grid-container;
	margin: 0 auto;
	max-width: 1140px;
	width: 100%;

	@media (min-width: 1150px) {
		background: url("assets/images/bg.jpg") repeat-y left top;
	}

	.Content {
		@include grid-column(1, $sidebar-right);

		@include grid-media($sidebar-right--tablet-up) {
			@include grid-column(2);
		}
	}

	.Sidebar {
		@include grid-column(1, $sidebar-right);

		@include grid-media($sidebar-right--tablet-up) {
			@include grid-column(1);
		}
	}
}

.page-template-template-reports .ContentWrap--sidebar {
	background: none;
}
