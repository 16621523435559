.Section {
    margin: 100px 0;
}

.Section-header {
    margin-bottom: $base-spacing;
    text-align: center;

    .Reports & {
        text-align: left;
    }

    .ReportsSection & {
        text-align: left;
    }
}

.Section-title {
    color: $blue;
    font-weight: normal;
}

.Section-subtitle {
    color: $medium-gray;
}

.Section-footer {
    text-align: center;
    padding-top: 60px;
}

.Section-buttons {
    .button {
        margin-bottom: 0.5em;

        @include grid-media($tablet-landscape) {
            margin-bottom: 0;
        }
    }
}
