.LowDown {
    border-bottom: 1px solid $light-gray;
    padding: 20px 0;
}

.LowDownContent {
    padding: 0 20px;
}

.LowDownContent p:last-of-type {
    margin: 0;
}

.LowDownIcon {
    margin-bottom: $small-spacing;

    @include grid-media($tablet-landscape) {
        margin-bottom: 0;
    }
}

.LowDownAction {
    margin-top: $small-spacing;
    @include grid-media($tablet-portrait) {
        margin-top: 0;
    }
}
