.Sidebar {
    ul,
    ol {
        @include list-reset;

        li {
            display: block;
        }
    }
}

.Sidebar-primary {
    margin-top: 40px;

    .page-template-template-reports & {
        margin-top: 0;
    }
}

.widget {
    margin-bottom: $base-spacing;

    ul > li > a {
        font-family: $heading-font-family;
    }
}

.widgettitle {
    border-bottom: $base-border;
    color: $base-font-color;
    margin-bottom: 12px;
    padding-bottom: 12px;
    font-size: 22px;
    font-size: 18px;
    font-weight: bold;
}

.widget_report_download {
    background: $blue;
    border-radius: $base-border-radius;
    padding: 20px;

    @include grid-media($tablet-landscape) {
        padding: 40px 30px;
    }

    #{$text-list} {
        color: #fff;
        text-align: center;
    }

    .button {
        display: block;
        width: 100%;

        &:hover {
            color: #fff;
        }
    }
}

.ReportDownloadPromo-title {
    font-family: $heading-font-family;
    font-weight: 700;
}

.widget_media_image img,
.Widget-chart img {
    display: block;
    margin: 0 auto;
}

.widget_yearly_archive li a {
    font-size: 20px;
    font-weight: bold;
    padding: 5px 0;
}

.SingleReportDownload {
    .button {
        width: 100%;
        margin-top: $small-spacing;
    }
}

.image-widget-modal img {
    display: block;
    margin: 0 auto;
}
