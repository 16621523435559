$text-list: "h1", "h2", "h3", "h4", "h5", "h6", "p", "date";

$title-list: "h1", "h2", "h3", "h4", "h5", "h6";

body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $blue;
	font-family: $heading-font-family;
	font-size: $base-font-size;
	line-height: $heading-line-height;
	margin: 0 0 $small-spacing;
}

h1,
.h1 {
	font-size: 32px;

	@media only screen and (min-width: 900px) {
		font-size: 40px;
	}
}

h2,
.h2 {
	font-size: 26px;

	@media only screen and (min-width: 900px) {
		font-size: 32px;
	}
}

h3,
.h3 {
	font-size: 22px;

	@media only screen and (min-width: 900px) {
		font-size: 24px;
	}
}

h4,
.h4 {
	font-size: 18px;
}

h5,
.h5 {
	font-size: $base-font-size;
}

h6,
.h6 {
	font-size: $base-font-size;
}

p {
	margin: 0 0 $small-spacing;

	@media only screen and (min-width: 900px) {
		font-size: $base-font-size;
	}
}

a {
	color: lighten($action-color, 15);
	text-decoration: none;
	transition: color $base-duration $base-timing;

	&:active,
	&:focus,
	&:hover {
		color: shade($action-color, 25%);
	}
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0;
}

pre {
	background: $light-gray;
	color: $base-font-color;
	font-family: $monospace;
	font-size: $base-font-size;
	margin-bottom: $base-spacing;
	max-width: 100%;
	overflow: auto;
	padding: 3% 10% 3% 5%;
	position: relative;
	tab-size: 4;
	white-space: pre-wrap;
}

code,
kbd,
tt,
var {
	background: $light-gray;
	border-radius: 2px;
	color: red;
	font-family: $monospace;
	font-size: $base-font-size;
	padding: 3px 5px;
}

address {
	font-style: italic;
	margin: 0 0 $base-spacing;
}

abbr,
acronym {
	border-bottom: 1px dotted $base-border-color;
	cursor: help;
}

mark,
ins {
	background: lighten($action-color, 45);
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: 0.5ex;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

figure {
	margin: 0;
}

blockquote,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	color: $base-font-color;
	font-family: $base-font-family;
	font-style: italic;

	cite {
		display: block;
		font-family: $base-font-family;
		font-style: normal;
		margin-top: 15px;

		&::before {
			content: "\2014";
			padding-right: 10px;
		}
	}
}

q {
	color: $base-font-color;
	font-family: $base-font-family;
	font-style: italic;
}

svg {
	display: block;
}
