.Nav {
	clear: both;
	text-align: right;
	width: 100%;

	ul {
		@include list-reset;
		list-style-type: none;

		> li {
			display: inline-block;
			list-style-type: none;
		}
	}

	.menu-item {
		display: block;
		margin: 0;
		text-align: left;

		@include grid-media($tablet-portrait) {
			display: inline-block;
		}

		&.current-menu-item > a {
			color: $yellow;
			font-weight: bold;
		}

		&.current-menu-item.menu-item-object-custom > a {
			font-weight: normal;

			&:hover {
				color: $subnav-link-color-hover;
				font-weight: bold;
			}
		}

		&:last-child a {
			padding-right: 0;
		}

		> a {
			color: $medium-gray;
			display: block;
			font-family: $heading-font-family;
			font-weight: 700;
			letter-spacing: 1px;
			padding: 10px 5px;
			text-transform: uppercase;
			transition: all $base-duration $base-timing;

			@media only screen and (min-width: 1025px) and (max-width: 1054px) {
				font-size: 14px;
			}

			@media only screen and (min-width: 1225px) {
				padding: 25px 15px;
			}

			&:hover,
			&:focus {
				color: $action-color;
			}
		}
	}

	ul ul {
		left: -9999px;
		opacity: 0;
		padding-top: 25px;
		position: absolute;
		transition: all $base-duration $base-timing;
		width: 250px;
		z-index: 500;

		.SiteFooter-menu & {
			padding-top: 0;
		}

		li.menu-item {
			background: #fff;
			border: none;
			margin: 0;
			position: relative;
			width: 100%;

			@media only screen and (min-width: 1025px) {
				border-bottom: 1px solid #fff;
				border-left: $base-border;
				border-right: $base-border;

				&.menu-item-type-custom.current-menu-item a {
					color: $base-font-color;
				}
			}

			&:first-child {
				@media only screen and (min-width: 1025px) {
					border-top-left-radius: $base-border-radius;
					border-top-right-radius: $base-border-radius;
					border-top: $base-border;
				}

				&:before {
					content: "";
					width: 0;
					height: 0;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-bottom: 10px solid #fff;
					position: absolute;
					top: -10px;
					left: 20px;
				}

				> a {
					padding-top: 20px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1025px) {
					border-bottom-left-radius: $base-border-radius;
					border-bottom-right-radius: $base-border-radius;
					border-bottom: $base-border;
				}

				> a {
					padding-bottom: 20px;
				}
			}
		}

		.menu-item > a {
			color: $subnav-link-color;
			font-size: $subnav-font-size;
			font-weight: 400;
			padding: 5px 20px;
			position: relative;
			text-transform: none;
			transition: all $base-duration $base-timing;
			width: 200px;

			&:hover,
			&:focus {
				color: $subnav-link-color-hover;
				font-weight: bold;
			}
		}

		.current-menu-item > a,
		.current-menu-ancestor > a,
		.current-menu-parent > a {
			color: $subnav-link-color-hover;

			&:hover,
			&:focus {
				color: $subnav-link-color-hover;
			}
		}

		.sub-menu {
			margin: -54px 0 0 200px;
		}
	}

	.menu-item:hover > ul,
	.menu-item-hover > ul,
	.menu-item:focus > ul {
		left: auto;
		opacity: 1;
	}
}

.Nav-toggle {
	width: 20px;
	height: 12px;
	margin-left: auto;
	position: relative;
	cursor: pointer;
	transform: rotate(0deg);
	transition: all 0.25s ease-in-out;

	@media only screen and (min-width: 1025px) {
		display: none;
	}

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $dark-gray;
		border-radius: 0;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.25s ease-in-out;

		&:nth-child(1) {
			top: 0px;
		}

		&:nth-child(2),
		&:nth-child(3) {
			top: 5px;
		}

		&:nth-child(4) {
			top: 10px;
		}
	}

	&.is-open span:nth-child(1) {
		opacity: 0;
	}

	&.is-open span:nth-child(2) {
		transform: rotate(45deg);
	}

	&.is-open span:nth-child(3) {
		transform: rotate(-45deg);
	}

	&.is-open span:nth-child(4) {
		top: 18px;
		width: 0%;
		left: 50%;
	}
}

.Nav-primary {
	background: $dark-gray;
	padding-left: 20px;
	position: fixed;
	bottom: 0;
	overflow-y: scroll;
	top: 0;
	transition: right 0.5s $base-timing;
	right: -260px;
	width: 260px;
	z-index: 500;

	@media (min-width: 1025px) {
		background: none;
		overflow-y: visible;
		padding: 0;
		position: static;
		width: auto;
	}

	&.is-open {
		right: 0;
	}

	.menu-item {
		display: block;

		@media only screen and (min-width: 1025px) {
			display: inline-block;
		}
	}

	> ul .menu-item a {
		@media (max-width: 1180px) {
			padding: 25px 10px;
		}
		@media (max-width: 1120px) {
			padding: 25px 5px;
		}
	}
}

.Nav.Nav-primary
	ul
	> ul
	> li.menu-item.menu-item-type-custom.current-menu-item
	a {
	color: #fff;

	@media only screen and (min-width: 1025px) {
		color: $base-font-color;
	}
}

.Nav-footer .menu-item.current-menu-item > a {
	color: #fff;
}

.SiteFooter-menu .Nav > ul .menu-item > a {
	color: #fff;

	&:hover {
		color: $light-gray;
	}
}

.SiteFooter-menu .Nav > ul .menu-item > ul a {
	color: $base-font-color;

	&:hover {
		color: $action-color;
	}
}

.Nav.Nav-footer {
	@media (max-width: 767px) {
		> ul {
			padding: 10px 0;
		}

		.menu-item a {
			color: $light-gray !important;
			padding-top: 10px;
			padding-bottom: 10px;

			&:hover {
				color: $light-gray !important;
				text-decoration: underline;
			}
		}

		li.menu-item {
			background: transparent !important;

			&:before {
				display: none;
			}
		}

		li ul {
			display: block;
			opacity: 1;
			left: auto;
			position: relative;
			padding: 0 0 0 10px;
			width: auto;

			&:after {
				display: none;
			}
		}

		ul ul li.menu-item:first-child > a {
			padding-top: 10px;
		}
	}
}

.Nav-primary.is-open {
	> ul {
		padding: 10px 0;
	}

	.menu-item a {
		color: white;
		padding-top: 10px;
		padding-bottom: 10px;

		&:hover {
			color: white;
			text-decoration: underline;
		}
	}

	li.menu-item {
		background: $dark-gray;
		&:before {
			display: none;
		}
	}

	li ul {
		display: block;
		opacity: 1;
		left: auto;
		position: relative;
		padding: 0 0 0 10px;
		width: auto;

		&:after {
			display: none;
		}
	}

	ul ul li.menu-item:first-child > a {
		padding-top: 10px;
	}
}

.ScrollTop {
	display: none;
	position: fixed;
	top: 5%;
	height: 24px;
	width: 24px;
	right: 2%;
	transform: rotate(180deg);

	@include grid-media($tablet-landscape) {
		display: block;
	}

	@media (min-width: 1320px) {
		right: 5%;
	}
}

.ScrollBottom {
	display: none;
	position: fixed;
	bottom: 5%;
	height: 24px;
	width: 24px;
	right: 2%;
	z-index: 1000;

	@include grid-media($tablet-landscape) {
		display: block;
	}

	@media (min-width: 1320px) {
		right: 5%;
	}
}
