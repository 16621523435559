$mqs-phone-portrait: (
	columns: 2,
	media: 359px, // $phone-portrait - 1px
);
$mqs-phone-landscape: (
	columns: 4,
	media: $phone-landscape,
);
$mqs-tablet-portrait: (
	columns: 4,
	media: $tablet-portrait,
);
$mqs-tablet-landscape: (
	columns: 8,
	media: $tablet-landscape,
);
$mqs-desktop: (
	columns: 12,
	media: $desktop,
);
$mqs-large-desktop: (
	columns: 12,
	media: $large-desktop,
);
$mqs-xl-desktop: (
	columns: 12,
	media: $large-desktop,
);

$sidebar-right: (
	columns: 1,
	gutter: 30px
);

$sidebar-right--tablet-up: (
	columns: 3,
	gutter: 30px,
	media: "(min-width: 768px)"
);