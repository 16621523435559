.CallToActionSection {
    padding: 60px 0;

    p:last-of-type {
        margin-bottom: 0;
    }

    .button a {
        color: #fff;
    }
}

.CallToActionSection--is-gray {
    background: $light-gray;
}

.CallToActionSection--is-blue {
    background: radial-gradient(circle, #4e6cba 0%, #3a518d 100%);

    #{$text-list} {
        color: #fff;
    }

    .button {
        background: #fff;
        color: $blue;

        &:hover {
            background: darken($blue, 10);

            a {
                color: #fff;
            }
        }

        a {
            color: $blue;
        }
    }
}
