ul.past-report-years,
li.past-report-year,
ul.past-report-posts,
li.past-report-post {
	@include list-reset;
}

li.past-report-year {
	margin-bottom: $base-spacing;

	> span {
		font-size: 24px;
		font-weight: bold;
	}
}

li.past-report-post {
	font-family: $heading-font-family;
	font-weight: bold;
	margin-bottom: 5px;
}
