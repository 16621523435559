.pagination {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .next,
    .prev {
        border: $base-border;
        border-radius: $base-border-radius;
        font-family: $heading-font-family;
        font-weight: 700;
        padding: 5px 10px;
        text-align: center;

        &:hover {
            background: $blue;
            color: #fff;
        }
    }

    ul.pages {
        @include list-reset;
        display: flex;
        justify-content: center;
        margin: 0;
        margin-top: 4px;
        padding: 0;
        text-align: center;
        width: 80%;

        li {
            list-style-type: none;

            &:first-of-type {
                .page-number {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }

            &:last-of-type {
                .page-number {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border-right: 1px solid #eee;
                }
            }

            .page-number,
            .dots {
                border-top: 1px solid #eee;
                border-left: 1px solid #eee;
                border-bottom: 1px solid #eee;
                font-weight: 700;
                font-size: 18px;
                padding: 5px 10px;
            }
        }
    }
}
