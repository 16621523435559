.LinkList {
    margin: 0;
    padding: 0;
}

.LinkListItem,
.LinkList > li {
    @include list-reset;
    margin-bottom: 10px;
}

.LinkListItem-link,
.LinkList > li > a {
    font-family: $heading-font-family;
}