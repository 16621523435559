.PropertySlider {
    .slick-prev,
    .slick-next {
        bottom: 0;
        top: 105%;

        &:before {
            color: #000;
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}