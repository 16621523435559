table {
    border: $base-border;
    border-color: darken($light-gray, 5);
    border-collapse: collapse;
    border-radius: $base-border-radius;
    margin: 0 0 $base-spacing;
    table-layout: fixed;
    width: 100%;
}

tbody {
    margin-bottom: $base-spacing;
}

th {
    background: $blue;
    border-bottom: $base-border;
    border-color: darken($light-gray, 5);
    font-weight: bold;
    padding: $small-spacing/2;
    text-align: left;

    h5 {
        color: #fff;
        font-size: 18px;
        margin: 0;
    }
}

td {
    border-bottom: $base-border;
    font-family: $heading-font-family;
    font-size: 14px;
    padding: $small-spacing/2;

    p {
        margin-bottom: 0;
    }
}

tr, td, th {
    vertical-align: middle;
}

tr:nth-child(even) {
    background: #eee;
}

.SingleProperty tr td:first-child {
    font-weight: bold;
}
