ol,
ul {
    margin-bottom: $base-spacing;
	margin-left: $small-spacing/2;

    @media only screen and (min-width:900px) {
        margin-left: $small-spacing;
    }
}

li {
	margin-bottom: 1%;
}

ol > li {
	list-style-type: decimal;
}

ul > li {
	list-style-type: disc;
}

li ol,
li ul {
	margin-top: 1%;
    margin-bottom: 1%;
}

dl {
    margin-bottom: $base-spacing;
}

dt {
    font-weight: bold;
    margin: 0;
}

dd {
    margin: 0;
    margin-bottom: $base-spacing/2;
}
