.sr-pagination {
	display: flex;
	align-items: center;

	a {
		border: $base-border;
		border-radius: $base-border-radius;
		font-family: $heading-font-family;
		font-weight: bold;
		padding: 5px 10px;

		&:hover {
			background: $blue;
			color: #fff;
		}

		&:last-of-type {
			margin-left: auto;
		}
	}
}

.PropertyDetails {
	margin-bottom: $base-spacing;
}

.SingleProperty .sr-gallery {
	margin-top: 0;
}

.SingleProperty-details {
	align-items: flex-start;
	border-bottom: $base-border;
	display: flex;
	flex-direction: row;
	padding-bottom: $small-spacing;
	margin-bottom: $base-spacing;

	@include grid-media($tablet-landscape) {
		align-items: flex-start;
		flex-direction: row;
	}
}

.SingleProperty-info {
	width: 100%;

	@include grid-media($tablet-landscape) {
		width: 70%;
	}
}

.SingleProperty-meta {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;

	div {
		margin-bottom: 1em;
		width: 50%;

		@include grid-media($tablet-portrait) {
			width: 33%;
		}

		@include grid-media($tablet-landscape) {
			margin: 0 1.5em;
			width: auto;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

.SingleProperty-metaValue,
.SingleProperty-metaLabel {
	display: block;
}

.SingleProperty-metaValue {
	font-size: 24px;
	font-weight: 700;
}

.SingleProperty-metaLabel {
	color: $medium-gray;
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: 400;
}

.SingleProperty-address h2 {
	color: $base-font-color;
	font-size: 14px;
	font-weight: normal;
	margin-top: $small-spacing/2;
	margin-bottom: 0;

	span:first-child {
		font-size: 16px;
		font-weight: bold;
	}
}

.SingleProperty-mapLink {
	border: 1px solid $dark-gray;
	position: absolute;
	margin-left: auto;
	top: -121px;
	right: 30px;
	z-index: 500;

	&:hover {
		.mapLinkButton {
			background: $action-color;
			color: #fff;
		}
	}

	img {
		display: block;
		width: 100px;
	}

	.mapLinkButton {
		color: $base-font-color;
		display: block;
		font-family: $heading-font-family;
		font-size: 14px;
		padding: 5px 18px;
		transition: all $base-duration $base-timing;
	}
}

.SingleProperty .owl-dots {
	font-family: $heading-font-family;
}

#details-map {
	margin-bottom: $small-spacing;
}

.sr-search .sr-minmax-filters {
	flex-direction: row;
	flex-wrap: wrap;

	@include grid-media($tablet-portrait) {
		flex-direction: row;
		flex-wrap: nowrap;
	}
}

.sr-search .sr-minmax-filters .sr-search-field {
	width: 49%;
	@include grid-media($tablet-portrait) {
		width: auto;
		margin-right: 5px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.sr-search #sr-search-minprice input,
.sr-search #sr-search-maxprice input,
.sr-search #sr-search-minbaths input,
.sr-search #sr-search-maxbaths input,
.sr-search #sr-search-minbeds input,
.sr-search #sr-search-maxbeds input {
	width: 100%;
}

.sr-search #sr-search-keywords {
	width: 100%;

	@include grid-media($tablet-portrait) {
		width: 70%;
	}
}

.sr-search #sr-search-ptype {
	width: 100%;

	@include grid-media($tablet-portrait) {
		width: 25%;
	}
}

.sr-search #sr-search-ptype select {
	margin-bottom: 20px;
}

#sr-search-wrapper .sr-search .submit {
	width: 100%;

	@include grid-media($tablet-portrait) {
		width: auto;
	}
}

.sr-adv-search-amenities-wrapper .sr-adv-search-amenities-wrapper-inner {
	padding: 20px;
}

.SingleProperty-nav {
	background: #fff;
	border: 1px solid rgba(48, 51, 57, 0.15);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	display: none;
	opacity: 0;
	position: fixed;
	width: 100%;
	transform: translateY(-100%);
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	z-index: 1000;
	top: 0;
	right: 0;
	left: 0;

	@include grid-media($tablet-landscape) {
		display: block;
	}
}

.SingleProperty-nav--is-sticky {
	opacity: 1;
	transform: translateY(0);
}

.SingleProperty-navContent,
.SingleProperty-navMeta {
	align-items: center;
	display: flex;
}

.SingleProperty-navAddress {
	font-size: 18px;
	font-weight: bold;
	padding-right: 20px;
}

.SingleProperty-navMeta {
	border-left: 1px solid rgba(48, 51, 57, 0.15);
	align-items: flex-start;
	padding: 10px 0;
	padding-left: 20px;
}

.SingleProperty-navMeta > div {
	margin-right: 1em;
}

.SingleProperty-navMeta .SingleProperty-metaValue {
	font-size: 18px;
}

.SingleProperty-navAction {
	margin-left: auto;
}

.SingleProperty-navAction .button {
	padding: 15px 30px;
}

$remarks-grid: (columns: 1);
$remarks-grid--tablet: (columns: 3, gutter: 40px, media: "(min-width: 768px)");

.SingleProperty-remarks {
	@include grid-container;
	@include grid-collapse($remarks-grid);
	margin-bottom: $base-spacing;
}

.SingleProperty-remarks h3 {
	@include grid-column(1, $remarks-grid);
	@include grid-media($remarks-grid--tablet) {
		@include grid-column(1);
	}
}

.SingleProperty-remarks p {
	@include grid-column(1, $remarks-grid);
	@include grid-media($remarks-grid--tablet) {
		@include grid-column(2);
	}
}

.SingleProperty-locationInfo {
	@include grid-container;
	@include grid-collapse($remarks-grid);

	@include grid-media($remarks-grid--tablet) {
		@include grid-collapse($remarks-grid--tablet);
	}
}

.SingleProperty-locationInfo .SingleProperty-keyDetails {
	@include grid-column(1, $remarks-grid);
	@include grid-media($remarks-grid--tablet) {
		@include grid-column(2);
	}
}

.SingleProperty-locationInfo .SingleProperty-locationDirections {
	@include grid-column(1, $remarks-grid);
	@include grid-media($remarks-grid--tablet) {
		@include grid-column(1);
	}
}

.SingleProperty-keyDetails {
	border-top: $base-border;
	border-bottom: $base-border;
	display: flex;
	flex-wrap: wrap;
	padding: $base-spacing 0;

	&.no-border {
		border: none;
		padding: 0;
	}
}

.SingleProperty-detail {
	margin-top: 1em;
	padding-right: 2em;
	width: 50%;

	@include grid-media($tablet-landscape) {
		margin-top: 0;
		padding-right: 1em;
		min-width: 16.66667%;
		max-width: 16.66667%;
		width: auto;
		&:nth-child(n + 7) {
			margin-top: $small-spacing;
		}

		.SingleProperty-locationInfo &,
		.in-thirds & {
			margin-bottom: $small-spacing;
			max-width: 33.33333%;
			min-width: 33.33333%;
		}
	}
}

.SingleProperty-detailKey {
	color: #868686;
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: 400;
}

.SingleProperty-features-wrap {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	padding: 60px 0;

	@include grid-media($tablet-landscape) {
		flex-direction: row;
	}
}

.SingleProperty-exterior,
.SingleProperty-interior {
	flex: 1;
	margin-bottom: 1.5em;

	@include grid-media($tablet-landscape) {
		margin-bottom: 0;
	}
}

.SingleProperty-features {
	display: flex;
	flex-wrap: wrap;
}

.SingleProperty-feature {
	min-width: 50%;
	@include grid-media($tablet-landscape) {
		min-width: 33.3333%;
	}
}

.SingleProperty-wrap {
	position: relative;
}

.SingleProperty-finance,
.SingleProperty-location,
.SingleProperty-moreDetails {
	margin: 60px 0;
}

$mapContact-grid: (columns: 1, gutter: 40px);
$mapContact-grid--tablet-up: (
	columns: 2,
	gutter: 40px,
	media: "(min-width: 768px)"
);

.SingleProperty-mapContact {
	@include grid-container;
	@include grid-collapse($mapContact-grid);
}

.SingleProperty #details-map {
	@include grid-column(1, $mapContact-grid);

	@include grid-media($mapContact-grid--tablet-up) {
		@include grid-column(1);
	}
}

.SingleProperty-contact {
	@include grid-column(1, $mapContact-grid);

	@include grid-media($mapContact-grid--tablet-up) {
		@include grid-column(1);
	}
}

.SingleProperty-social {
	display: flex;
	align-items: center;
	margin-bottom: $base-spacing;
	padding-top: 30px;

	@include grid-media($tablet-portrait) {
		padding-top: 0;
	}
}

.SingleProperty-association {
	font-size: 10px;
}

.Map-disclaimer {
	font-size: 12px;
	margin-top: 5px;
}

.SingleProperty-extras {
	margin-top: $small-spacing;
	width: 100%;
}

.SingleProperty-history {
	th {
		color: #fff;
	}
}

.SingleProperty-wrap h5 {
	font-size: 1.3em;
}
