.SearchSection {
    margin: 60px 0;

    .button {
        padding: 10px 20px;
    }
}

.Search-map {
    @include grid-collapse($mqs-desktop);
    @include grid-container;
}

#aspenMap,
#snowmassMap {
    border-radius: $base-border-radius;
    height: 480px;
    margin-right: $small-spacing;
    margin-left: $small-spacing;

    @include grid-media($tablet-portrait) {
        @include grid-column(7, $mqs-desktop);
    }
}

.Neighborhoods {
    margin-top: $small-spacing;

    @include grid-media($tablet-portrait) {
        margin-top: 0;
        @include grid-column(5, $mqs-desktop);
    }
}

.Neighborhood {
    opacity: 0;
    height: 0;
    visibility: hidden;

    @include grid-media($tablet-landscape) {
        position: absolute;
        top: 0;
        left: 100%;
        opacity: 0;
        height: auto;
        transition: all 0.3s ease-in-out;
        width: 100%;
        visibility: visible;
    }

    &.is-active {
        opacity: 1;
        height: auto;
        visibility: visible;

        @include grid-media($tablet-landscape) {
            left: 0;
        }
    }
}

.Search-regions {
    @include grid-collapse($mqs-desktop);
    @include grid-container;
    margin-top: $base-spacing;
}

.SearchRegion {
    margin-top: $small-spacing;
    @include grid-column(12, $mqs-desktop);

    &:first-of-type {
        margin-top: 0;
    }

    @include grid-media($tablet-portrait) {
        @include grid-column(6, $mqs-desktop);
        margin-top: 0;
    }
}

.SearchRegion-links,
.SearchRegion-links li {
    @include list-reset;
}

.SearchRegion-links {
    margin: 0;
    padding: 0;

    li {
        margin-bottom: 10px;
    }
}

.Map-info,
.Search-form {
    h3 {
        font-weight: normal;
    }

    p {
        color: $medium-gray;
    }
}

.Map-info h3 {
    margin-bottom: 5px;
}

.SearchSlider a {
    color: #3a518d;
    font-family: $heading-font-family;
    font-size: 14px;
    font-weight: bold;
    margin-right: 12px;
}
