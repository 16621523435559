.Feed {
    padding: 60px 0 40px;
}

.FeedColumns {
    @include grid-container;

    @include grid-media($tablet-portrait) {
        @include grid-collapse($tablet-portrait);
    }

    @include grid-media($tablet-landscape) {
        @include grid-collapse($tablet-landscape);
    }
}

.FeedColumn {
    padding-right: 20px;
}

.FeedColumn-1 {
    @include grid-media($tablet-portrait) {
        @include grid-column(6);
    }

    @include grid-media($tablet-landscape) {
        @include grid-column(5);
        border-right: $base-border;
        border-color: $dark-gray;
    }
}

.FeedColumn-2 {
    @include grid-media($tablet-portrait) {
        @include grid-column(6);
    }

    @include grid-media($tablet-landscape) {
        @include grid-column(3);
        margin-right: 0;
        padding-right: 0;
    }
}

.FeedColumn-3 {
    @include grid-media($tablet-portrait) {
        @include grid-column(12);
    }

    @include grid-media($tablet-landscape) {
        @include grid-column(4);
        border-left: $base-border;
        border-color: $dark-gray;
        padding-left: $small-spacing;
    }
}

.FeedList {
    margin: 0;
    padding: 0;

    .widget {
        @include list-reset;
        margin-bottom: $base-spacing;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.FeedList-title {
    color: $base-font-color;
    font-size: 22px;
    font-weight: bold;
}

.FeedList-more {
    font-size: 18px;
    font-weight: normal;

    .widget_recent_reports & {
        padding-left: 10px;
    }
}
