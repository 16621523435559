$property-gallery: (columns: 1, gutter: 30px);
$property-gallery--tablet-up: (
    columns: 2,
    gutter: 30px,
    media: "(min-width: 768px)"
);
$property-gallery--desktop-up: (
    columns: 3,
    gutter: 30px,
    media: "(min-width: 1000px)"
);

.FeaturedPropertiesSection {
    background: $light-gray;
    padding: 100px 0;
}

.FeaturedProperties {
    @include grid-collapse($mqs-desktop);
    @include grid-container;
}

.FeaturedProperty {
    @include grid-column(12, $mqs-desktop);

    @include grid-media($tablet-portrait) {
        @include grid-column(4, $mqs-desktop);
        margin-bottom: 0;
    }
}

.FeatureProperty-image {
    margin: 0;

    img {
        border-radius: $base-border-radius;
    }
}

#sr_map_canvas {
    border-radius: $base-border-radius;
}

.Properties {
    @include grid-collapse($property-gallery);
    @include grid-container;
    margin-top: $base-spacing;

    .Property {
        @include grid-column(1, $property-gallery);
        margin-bottom: $base-spacing;

        @include grid-media($property-gallery--tablet-up) {
            @include grid-column(1, $property-gallery--tablet-up);
        }

        @include grid-media($property-gallery--desktop-up) {
            @include grid-column(1, $property-gallery--desktop-up);
        }
    }
}

.Property {
    font-family: $heading-font-family;
}

.Property-image {
    background-size: cover;
    background-position: center;
    border-radius: $base-border-radius;
    min-height: 275px;
    margin-bottom: 8px;
    position: relative;
}

.Property-price {
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
    color: #fff;
    font-weight: 700;
    left: 0;
    margin: 0;
    padding: $small-spacing;
    position: absolute;
    right: 0;
}

.Property-address {
    font-size: 14px;
    font-weight: 400;
}

.Property-meta {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    ul,
    li {
        @include list-reset;
        font-size: 14px;
    }
}

.SingleProperty-galleryItem > img {
    max-height: 100%;
}