.Modal {
    display: none;
}

.Modal-header {
    background: radial-gradient(circle, #4E6CBA 0%, #3A518D 100%);
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
    clip-path: polygon(0 50%, 50% 100%, 100% 50%, 100% 0, 0 0);
}

.Modal-title {
    color: #fff;
    padding: $base-spacing 0;
    text-align: center;
    text-transform: uppercase;
}

.Modal-content {
    color: $medium-gray;
    font-family: $heading-font-family;
    font-size: 1.4em;
    font-style: italic;
    font-weight: 700;
    text-align: center;
    padding: 0 70px;
}

.Modal-form {
    margin-bottom: $base-spacing;
    padding: 0 70px;

    .gform_wrapper ul.gform_fields li.gfield {
        padding-right: 0 !important;
    }

    .gform_wrapper .top_label input.medium, 
    .gform_wrapper .top_label select.medium {
        width: 100%;
    }

    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        padding: 16px 20px;
    }

    .gform_wrapper .gform_footer input.button, 
    .gform_wrapper .gform_footer input[type=submit], 
    .gform_wrapper .gform_page_footer input.button, 
    .gform_wrapper .gform_page_footer input[type=submit] {
        margin: 0;
        width: 100%;
    }

    .gform_confirmation_message {
        text-align: center;

        .button {
            width: 100%;
        }
    }

    .gform_wrapper .gform_ajax_spinner {
        margin-top: 10px;
    }
}

.modaal-container {
    border-radius: $base-border-radius !important;
}

.modaal-content-container {
    padding: 0 !important;
}