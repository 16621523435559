.NeighborhoodSearches {
    &:last-of-type {
        margin-bottom: $small-spacing;
    }
    p:first-of-type {
        color: $base-font-color;
        font-family: $heading-font-family;
        margin-bottom: 5px;
    }

    P:last-of-type {
        margin-bottom: 5px;
    }
}

.Neighborhoods {
    position: relative;
    padding: 0 20px;

    @include grid-media($tablet-portrait) {
        padding: 0;
    }
}

.Map-info {
    top: 0;

    @include grid-media($tablet-landscape) {
        position: absolute;
    }
}

.fadeIn-enter {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
}

.fadeIn-enter.fadeIn-enter-active {
    opacity: 1;
    transform: none;
    transition: all 200ms ease-in;
}

.fadeIn-leave {
    opacity: 1;
    transform: none;
}

.fadeIn-leave.fadeIn-leave-active {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
    transition: all 200ms ease-in;
}

.SearchSlider {
    margin: 0 25px;
    margin-bottom: $small-spacing;
    width: 300px !important;

    .slick-prev:before,
    .slick-next:before {
        color: #000;
    }
}
