/**
 * Text Truncate
 *
 * Truncate text with an ellipis
 */
.u-textTruncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
}

/**
 * Horizontal text alignment
 */
.u-textCenter {
    text-align: center;
}

.u-textLeft {
    text-align: left;
}

.u-textRight {
    text-align: right;
}

.u-titleBorder {
    border-bottom: $base-border;
    margin-bottom: 12px;
    padding-bottom: 12px;
}