.search-results #searchform {
    margin-bottom: $base-spacing;
}

.search-results .PostTitle {
    font-size: 24px;
}

.searchform {
    display: block;
    position: relative;

    input[type="submit"] {
        display: block;
        width: 100%;
    }

    @include grid-media($tablet-landscape) {
        input[type="submit"] {
            background: $blue;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            bottom: 0;
            display: inline-block;
            padding: 0 2em;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
        }
    }
}

.SearchForm {
    select {
        background: #fff;
        border: 2px solid #ddd;
        color: tint($base-font-color, 40%);
        height: 46px;
    }

    select[multiple] {
        height: auto;
    }

    .sr-adv-search-part .sr-adv-search-col4 input {
        width: 100%;
    }

    #sr-search-ptype select {
        width: 100%;
    }

    .sr-sort-wrapper {
        float: none;
        margin-top: 0;

        @include grid-media($tablet-portrait) {
            float: right;
        }
    }
}

#sr-search-wrapper.one-click-search {
    h3,
    .sr-minmax-filters,
    .submit {
        display: none;
    }

    + .PropertiesCount {
        float: none;
    }
}

.sr-adv-search-wrap.SearchForm {
    padding: 10px;

    @include grid-media($tablet-landscape) {
        padding: 30px;
    }

    h2 {
        padding: 0 10px;
    }

    .submit {
        margin-left: 10px;
    }
}

.SearchForm .sr-adv-search-part li {
    width: 50%;

    @include grid-media($tablet-portrait) {
        width: 25%;
    }
}

.sr-sort-wrapper {
    label {
        font-weight: normal;
    }
    select {
        height: auto;
    }
}

.sr-disclaimer {
    background: #eee;
    border-radius: $base-border-radius;
    margin-bottom: $base-spacing;
    padding: 10px;
}

.PropertyDetails + .sr-disclaimer {
    clear: both;
    margin-top: $base-spacing;
}
