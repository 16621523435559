#{$all-buttons},
.button {
	appearance: none;
	background-color: $action-color;
	border: 0;
	border-radius: $base-border-radius;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: $heading-font-family;
	font-size: $base-font-size;
	-webkit-font-smoothing: antialiased;
	font-weight: 600;
	line-height: 1;
	padding: $small-spacing $small-spacing;
	text-align: center;
	text-decoration: none;
	transition: background-color $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;

	@media only screen and (min-width: 1024px) {
		padding: $small-spacing $base-spacing;
	}

	&:hover,
	&:focus {
		background-color: shade($action-color, 20%);
		color: #fff;
		text-decoration: none;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			background-color: $action-color;
		}
	}
}

.button-white {
	background: #fff;
	color: $action-color;
}

.button-double {
	line-height: 22px;
	min-width: 280px;
}

.LinkArrow {
	border-bottom: 2px solid $action-color;
	font-family: $heading-font-family;
	font-weight: bold;
	padding: 2px 5px;
	transition: all $base-duration $base-timing;

	&:hover a {
		color: #fff;
	}

	&:before {
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IgoJIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGlkPSJGaWxsZWRfSWNvbnMiIGZpbGw9IiMzQTUxOEQiPgoJPHBhdGggZD0iTTIzLjg1MiwxMS4xNDRMMTMuNzAzLDEuMDk2Yy0wLjk2LTAuOTYtMi42NzgtMC45MjQtMy42OCwwLjA3NWMtMS4wMzYsMS4wMzUtMS4wNywyLjY4Ny0wLjA2OSwzLjY5bDQuMzIxLDQuMTQzSDIuMDMKCQljLTEuMjcsMC0yLjAzLDEuMjcyLTIuMDMsMi41YzAsMC42MTcsMC4xNjgsMS4yMDcsMC40NzIsMS42NTljMC4zNjksMC41NDksMC45MTMsMC44NTEsMS41MywwLjg1MWgxMi4yNzZsLTQuMTU2LDQuMTYKCQljLTAuNDU2LDAuNDU0LTAuNzA4LDEuMDctMC43MDgsMS43MzRjMCwwLjcwOCwwLjI5MywxLjQwOSwwLjgwNywxLjkyMmMwLjUxMiwwLjUxMiwxLjIxMSwwLjgwNiwxLjkxOSwwLjgwNgoJCWMwLjY2NCwwLDEuMjgtMC4yNTEsMS43MzktMC43MDhsOS45NzctMTAuMDc2QzI0LjA1LDExLjY1NSwyNC4wNDgsMTEuMzM5LDIzLjg1MiwxMS4xNDR6Ii8+CjwvZz4KPGcgaWQ9IkZyYW1lcy0yNHB4Ij4KCTxyZWN0IGZpbGw9Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIvPgo8L2c+Cjwvc3ZnPgo=');
		background-size: contain;
		content: "";
		display: inline-block;
		margin-right: 5px;
		position: relative;
		height: 14px;
		top: 2px;
		transition: all $base-duration $base-timing;
		width: 14px;
	}

	&:hover {
		background-color: $blue;
		color: #fff;

		&:before {
			background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IgoJIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGlkPSJGaWxsZWRfSWNvbnMiIGZpbGw9IiNmZmYiPgoJPHBhdGggZD0iTTIzLjg1MiwxMS4xNDRMMTMuNzAzLDEuMDk2Yy0wLjk2LTAuOTYtMi42NzgtMC45MjQtMy42OCwwLjA3NWMtMS4wMzYsMS4wMzUtMS4wNywyLjY4Ny0wLjA2OSwzLjY5bDQuMzIxLDQuMTQzSDIuMDMKCQljLTEuMjcsMC0yLjAzLDEuMjcyLTIuMDMsMi41YzAsMC42MTcsMC4xNjgsMS4yMDcsMC40NzIsMS42NTljMC4zNjksMC41NDksMC45MTMsMC44NTEsMS41MywwLjg1MWgxMi4yNzZsLTQuMTU2LDQuMTYKCQljLTAuNDU2LDAuNDU0LTAuNzA4LDEuMDctMC43MDgsMS43MzRjMCwwLjcwOCwwLjI5MywxLjQwOSwwLjgwNywxLjkyMmMwLjUxMiwwLjUxMiwxLjIxMSwwLjgwNiwxLjkxOSwwLjgwNgoJCWMwLjY2NCwwLDEuMjgtMC4yNTEsMS43MzktMC43MDhsOS45NzctMTAuMDc2QzI0LjA1LDExLjY1NSwyNC4wNDgsMTEuMzM5LDIzLjg1MiwxMS4xNDR6Ii8+CjwvZz4KPGcgaWQ9IkZyYW1lcy0yNHB4Ij4KCTxyZWN0IGZpbGw9Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIvPgo8L2c+Cjwvc3ZnPgo=');
		}
	}
}

.PillButton {
	background: #fff;
	border: 1px solid #3a518d;
	border-radius: 3px;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial",
		sans-serif;
	padding: 6px 12px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;

	&:hover {
		background: $blue;
		color: #fff;

		a {
			color: #fff;
		}
	}
}
