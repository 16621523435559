.OneClickGroup {
    margin-bottom: $base-spacing;
}

.OneClickGroupLinks,
.OneClickGroupLinks li {
    @include list-reset;
}

.OneClickGroupLinks {
    margin: 0;
    padding: 0;

    li {
        display: block;
        margin-bottom: $small-spacing;
        &:hover {
            a {
                background-color: $blue;
                color: #fff;

                &:before {
                    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IgoJIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGlkPSJGaWxsZWRfSWNvbnMiIGZpbGw9IiNmZmYiPgoJPHBhdGggZD0iTTIzLjg1MiwxMS4xNDRMMTMuNzAzLDEuMDk2Yy0wLjk2LTAuOTYtMi42NzgtMC45MjQtMy42OCwwLjA3NWMtMS4wMzYsMS4wMzUtMS4wNywyLjY4Ny0wLjA2OSwzLjY5bDQuMzIxLDQuMTQzSDIuMDMKCQljLTEuMjcsMC0yLjAzLDEuMjcyLTIuMDMsMi41YzAsMC42MTcsMC4xNjgsMS4yMDcsMC40NzIsMS42NTljMC4zNjksMC41NDksMC45MTMsMC44NTEsMS41MywwLjg1MWgxMi4yNzZsLTQuMTU2LDQuMTYKCQljLTAuNDU2LDAuNDU0LTAuNzA4LDEuMDctMC43MDgsMS43MzRjMCwwLjcwOCwwLjI5MywxLjQwOSwwLjgwNywxLjkyMmMwLjUxMiwwLjUxMiwxLjIxMSwwLjgwNiwxLjkxOSwwLjgwNgoJCWMwLjY2NCwwLDEuMjgtMC4yNTEsMS43MzktMC43MDhsOS45NzctMTAuMDc2QzI0LjA1LDExLjY1NSwyNC4wNDgsMTEuMzM5LDIzLjg1MiwxMS4xNDR6Ii8+CjwvZz4KPGcgaWQ9IkZyYW1lcy0yNHB4Ij4KCTxyZWN0IGZpbGw9Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIvPgo8L2c+Cjwvc3ZnPgo=');
                }
            }
        }

        a {
            border-bottom: 2px solid $action-color;
            font-family: $heading-font-family;
            font-weight: bold;
            padding: 2px 5px;
            transition: all $base-duration $base-timing;

            &:before {
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IgoJIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGlkPSJGaWxsZWRfSWNvbnMiIGZpbGw9IiMzQTUxOEQiPgoJPHBhdGggZD0iTTIzLjg1MiwxMS4xNDRMMTMuNzAzLDEuMDk2Yy0wLjk2LTAuOTYtMi42NzgtMC45MjQtMy42OCwwLjA3NWMtMS4wMzYsMS4wMzUtMS4wNywyLjY4Ny0wLjA2OSwzLjY5bDQuMzIxLDQuMTQzSDIuMDMKCQljLTEuMjcsMC0yLjAzLDEuMjcyLTIuMDMsMi41YzAsMC42MTcsMC4xNjgsMS4yMDcsMC40NzIsMS42NTljMC4zNjksMC41NDksMC45MTMsMC44NTEsMS41MywwLjg1MWgxMi4yNzZsLTQuMTU2LDQuMTYKCQljLTAuNDU2LDAuNDU0LTAuNzA4LDEuMDctMC43MDgsMS43MzRjMCwwLjcwOCwwLjI5MywxLjQwOSwwLjgwNywxLjkyMmMwLjUxMiwwLjUxMiwxLjIxMSwwLjgwNiwxLjkxOSwwLjgwNgoJCWMwLjY2NCwwLDEuMjgtMC4yNTEsMS43MzktMC43MDhsOS45NzctMTAuMDc2QzI0LjA1LDExLjY1NSwyNC4wNDgsMTEuMzM5LDIzLjg1MiwxMS4xNDR6Ii8+CjwvZz4KPGcgaWQ9IkZyYW1lcy0yNHB4Ij4KCTxyZWN0IGZpbGw9Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIvPgo8L2c+Cjwvc3ZnPgo=');
                background-size: contain;
                content: "";
                display: inline-block;
                margin-right: 5px;
                position: relative;
                height: 14px;
                top: 2px;
                transition: all $base-duration $base-timing;
                width: 14px;
            }
        }
    }
}

.OneClickGroupLink-label {
    line-height: 2em;
}

.OneClickGroup--neighborhood {
    margin-bottom: $base-spacing;
}
