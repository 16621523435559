#wpadminbar {
	display: none;

	@include grid-media($tablet-portrait) {
		display: block;
	}
}

.SiteHeader,
.SiteContainer,
.SiteInner,
.SiteFooter {
	@include clearfix;
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
	position: absolute !important;
	white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.hide {
	display: none !important;
}

html .mceContentBody,
body#tinymce {
	height: auto !important;
}

.tiled-gallery {
	margin-bottom: $small-spacing !important;
}

.alignright {
	display: block;
	margin-left: $small-spacing !important;
	margin-bottom: $small-spacing !important;

	@include grid-media($tablet-landscape) {
		display: inline;
		float: right;
	}
}

.alignleft {
	display: block;
	margin-right: $small-spacing !important;
	margin-bottom: $small-spacing !important;

	@include grid-media($tablet-landscape) {
		display: inline;
		float: left;
	}
}

.aligncenter {
	display: block;
	margin: 0 auto;
}

.wp-caption-text {
	font-size: 10px;
}
