.Banner {
	background-color: $blue;
	background: radial-gradient(circle, #4e6cba 0%, #3a518d 100%);
	border-top: 5px solid $yellow;

	.wrap {
		position: relative;
	}
}

.Banner-content {
	padding: 40px 0;
	min-height: 162px;
	text-align: center;

	.BannerTitle {
		color: #fff;
		margin: 0;
		width: 100%;
	}

	.BannerSubTitle {
		color: $light-gray;
		font-size: 18px;
		margin-bottom: 0;
		margin-top: 15px;
	}

	.single-post &,
	.single-flip_job & {
		flex-direction: column;
	}
}

.BannerContent--page {
	text-align: center;
	margin: 0 auto;
	max-width: 880px;

	@media( min-width: 800px ) {
		width: 70%;
	}

	#{$text-list} {
		color: #fff;
	}

	a {
		display: inline-block;
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.Banner-shield {
	display: none;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	@include grid-media($tablet-landscape) {
		display: block;
	}
}

.home {
	.BannerTitle {
		margin: 0 0 20px;
	}

	.Banner-content {
		padding: 0;
		position: relative;
		text-align: left;
		flex-direction: column;

		@include grid-media($tablet-landscape) {
			flex-direction: row;
		}
	}

	.BannerContent,
	.BannerTestimonials {
		width: 100%;
		padding: 2em 0;
	}

	.BannerContent {
		@include grid-media($tablet-landscape) {
			width: 32%;
		}

		#{$text-list} {
			color: #fff;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	.BannerDivider {
		display: none;
		margin: 0 60px;

		@include grid-media($tablet-landscape) {
			display: block;
		}
	}

	.BannerTestimonials {
		border-top: $base-border;
		border-color: $action-color;

		@include grid-media($tablet-landscape) {
			border: none;
			width: 58%;
		}

		.Testimonial #{$text-list},
		.TestimonialAuthor {
			color: #fff;
		}
	}

	.BannerBadge {
		display: none;

		@media (min-width: 980px) {
			bottom: 4.5em;
			display: block;
			float: right;
			right: 0;
			margin-top: -40px;

			img {
				display: block;
			}
		}
	}

	.BannerTitle {
		font-size: 20px;
	}
}
