.TestimonialsSlider {
    margin-bottom: 30px;
    height: 0;
    visibility: hidden;

    &.slick-initialized {
        height: auto;
        visibility: visible;
    }
}

.Testimonial {
    margin: 0;
    width: 100%;

    blockqoute p {
        font-family: $heading-font-family;
        font-size: 20px;
        font-weight: 300;

        .TestimonialWidgetSlider & {
            font-size: $base-font-size;
        }
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}

.TestimonialAuthor {
    display: block;
    font-family: $heading-font-family;
    font-style: normal;
    font-weight: 700;
    margin-top: $small-spacing;
}
